import React from "react"
import Seo from "../components/seo"
import { Box, Heading } from "@chakra-ui/react"
import { LIVESTREAM_URL } from "../utils/constants"

const LivestreamPage = () => {
  return (
    <>
      <Seo />
      <Box
        bg="blackAlpha.900"
        minH="100vh"
      >
        <Heading
          fontSize={{ base: 80, md: 80 }}
          lineHeight="1"
          color="whiteAlpha.900"
          textAlign="center"
        >
          Tammie & Yoni
        </Heading>
        <Box pt="56.25%" pos="relative">
          <iframe
            title="Vimeo Livestream"
            src={LIVESTREAM_URL}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default LivestreamPage
